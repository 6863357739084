div.combo-box {
	width: 100%;
	z-index: 100;
	font-family: Arial, sans-serif;
}

div.combo-box-single {
	& :nth-child(3) {
		margin-top: 0;
		width: auto;
		border-radius: 0;

		& > div {
			padding: 0;

			& > div {
				padding: 2px 6px;
			}
		}
	}
}

.is-dirty,
div.combo-box.is-dirty:nth-child(1) > div:nth-child(1) > div:nth-child(1),
div.combo-box.is-dirty:nth-child(1) > div:nth-child(2) > div:nth-child(1) {
	background-color: #e0fffd;
}
